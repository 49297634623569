/*!* ===== NAVBAR SECTION ===== *!*/

/*.navbar-brand {*/
/*    color: white;*/
/*    padding: 0 15px;*/
/*    padding-top: 10px;*/
/*    padding-bottom: 5px;*/
/*    font-family: 'Josefin Sans', sans-serif;*/
/*    font-size: 25px;*/
/*    font-weight: 400;*/
/*    letter-spacing: 3px;*/
/*}*/

/*.nav-menu {*/
/*    background: transparent;*/
/*    !*margin-top: 0px;*!*/
/*    padding: 30px;*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    z-index: 9999;*/

/*    transition: all .4s;*/
/*}*/

/*@media (max-width:34em) {*/
/*    .nav-menu {*/
/*        background: #4f1b1d !important;*/
/*    }*/
/*}*/

/*.nav-item {*/
/*    padding: 0 10px;*/
/*}*/

/*.menu-item {*/
/*    font-family: 'Josefin Sans', sans-serif;*/
/*    font-size: 11px;*/
/*    letter-spacing: 2px;*/
/*    color: white;*/
/*    text-transform: uppercase;*/
/*}*/

/*@media (max-width: 34em) {*/
/*    .menu-item {*/
/*        padding: 10px 10px 10px;*/
/*    }*/
/*}*/

/*.navbar-toggler {*/
/*    cursor: pointer;*/
/*    outline: 0;*/
/*}*/

/*.ham {*/
/*    transition: width 0.3s;*/
/*    background: #F9F8F7;*/
/*}*/

/*.line1, .line3  {*/
/*    width: 28px;*/
/*    height: 2px;*/
/*    margin: 6px;*/
/*    transition:  0.4s;*/

/*}*/

/*.change .line1 {*/
/*    transform: rotate(-45deg) translate(-3px, 2px);*/
/*}*/

/*.change .line3 {*/
/*    transform: rotate(45deg) translate(-3px, -2px);*/
/*}*/

/*.menu-item:hover {*/
/*    color: #ffd03d;*/
/*}*/

/*.nav-active {*/
/*    color: #ffd03d;*/
/*}*/

/*.dropdown-item {*/
/*    font-family: 'Josefin Sans', sans-serif;*/
/*    text-transform: uppercase;*/
/*    font-size: 13px;*/
/*    letter-spacing: 1px;*/
/*}*/

/*.costum-navbar {*/
/*    padding-top: 5px;*/
/*    padding-bottom: 5px;*/
/*    background-color:#4f1b1d !important;*/
/*    position: fixed;*/
/*}*/

/*!* ===== END OF NAVBAR SECTION ===== *!*/


/*!* ===== HERO SECTION ===== *!*/

/*!*.hero-section {*!*/
/*!*    background: linear-gradient(*!*/
/*!*        rgba(0, 0, 0, 0),*!*/
/*!*        rgba(0, 0, 0, 0)),*!*/
/*!*        url('../../../images/View1.jpg');*!*/

/*!*    width: 100%;*!*/

/*!*    height: 100vh;*!*/
/*!*    background-size: cover;*!*/
/*!*    background-position: center;*!*/
/*!*    background-repeat: no-repeat;*!*/

/*!*    display: flex;*!*/
/*!*    justify-content: center;*!*/
/*!*    align-items: center;*!*/
/*!*}*!*/

/*.text-hero {*/
/*    font-family: 'Josefin Sans', sans-serif;*/
/*    font-size: 70px;*/
/*    font-weight: 700;*/
/*    letter-spacing: 2px;*/
/*}*/

/*.sub-text-hero {*/
/*    font-family: 'Dosis', sans-serif;*/
/*    letter-spacing: 9px;*/
/*    color: orange;*/
/*}*/

/*.nav-bottom {*/
/*    background: transparent !important;*/
/*    padding: 30px;*/
/*    position: absolute;*/
/*    width: 100%;*/

/*    bottom: 10px;*/
/*}*/

/*.item-bottom {*/
/*    padding: 0 20px;*/
/*    transition: .2s;*/
/*    font-size: 20px;*/
/*}*/

/*.item-bottom:hover {*/
/*    background: transparent;*/
/*    transform: translateY(-2px);*/
/*}*/

/*!* ===== END OF HERO SECTION ===== *!*/

/*!* ===== SKILLS ===== *!*/

/*li { list-style: none; }*/

/*#skillsHolder {*/
/*  float: left;*/
/*  min-width: 925px;*/
/*  width: 100%;*/
/*  height: auto;*/
/*  margin-top: 20px;*/
/*}*/

/*#skillCenter {*/
/*  width: 600px;*/
/*  margin: 0 auto;*/
/*}*/

/*#skillCenter h2 {*/
/*  text-align: center;*/
/*  color: grey;*/
/*!*  margin: 60px 0 4px 15px;*!*/
/*  font-family: 'Josefin Sans', sans-serif;*/
/*  font-size: 1.6rem;*/
/*}*/

/*.skills {*/
/*  background: #4D93BF;*/
/*  width: 300px;*/
/*  margin: 15px 0px;*/
/*  padding: 8px 0px 8px 6px;*/
/*  font-family: arial;*/
/*  font-size: .9em;*/
/*}*/

/*.skills p {*/
/*  margin: 0px 10px;*/
/*  color: #F2F2F2;*/
/*}*/



/*.container {*/
/*	max-width: 100rem;*/
/*	margin: 0 auto;*/
/*	padding: 0 2rem 2rem;*/
/*}*/

/*.gallery {*/
/*	display: flex;*/
/*	flex-wrap: wrap;*/
/*	!* Compensate for excess margin on outer gallery flex items *!*/
/*	margin: -1rem -1rem;*/
/*}*/

/*.gallery-item {*/
/*	!* Minimum width of 24rem and grow to fit available space *!*/
/*	flex: 1 0 20rem;*/
/*	!* Margin value should be half of grid-gap value as margins on flex items don't collapse *!*/
/*	margin: 1rem;*/
/*	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);*/
/*	overflow: hidden;*/
/*}*/

/*.gallery-image {*/
/*	display: block;*/
/*	width: 100%;*/
/*	height: 100%;*/
/*	object-fit: cover;*/
/*	transition: transform 400ms ease-out;*/
/*}*/

/*.gallery-image:hover {*/
/*	transform: scale(1.15);*/
/*}*/

/*!* ===== end of gallery ===== *!*/


/*!* STYLES SPECIFIC TO FOOTER  *!*/
/*.footer {*/
/*  width: 100%;*/
/*  position: relative;*/
/*  height: auto;*/
/*  background-color: #4f1b1d;*/
/*}*/
/*.footer .col {*/
/*  width: 190px;*/
/*  height: auto;*/
/*  float: left;*/
/*  box-sizing: border-box;*/
/*  -webkit-box-sizing: border-box;*/
/*  -moz-box-sizing: border-box;*/
/*  padding: 0px 20px 20px 20px;*/
/*}*/
/*.footer .col h1 {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: inherit;*/
/*  font-size: 12px;*/
/*  line-height: 17px;*/
/*  padding: 20px 0px 5px 0px;*/
/*  color: rgba(255,255,255,0.2);*/
/*  font-weight: normal;*/
/*  text-transform: uppercase;*/
/*  letter-spacing: 0.250em;*/
/*}*/
/*.footer .col ul {*/
/*  list-style-type: none;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*.footer .col ul li {*/
/*  color: #999999;*/
/*  font-size: 14px;*/
/*  font-family: inherit;*/
/*  font-weight: bold;*/
/*  padding: 5px 0px 5px 0px;*/
/*  cursor: pointer;*/
/*  transition: .2s;*/
/*  -webkit-transition: .2s;*/
/*  -moz-transition: .2s;*/
/*}*/
/*.social ul li {*/
/*  display: inline-block;*/
/*  padding-right: 5px !important;*/
/*}*/

/*.footer .col ul li:hover {*/
/*  color: #ffffff;*/
/*  transition: .1s;*/
/*  -webkit-transition: .1s;*/
/*  -moz-transition: .1s;*/
/*}*/
/*.clearfix {*/
/*  clear: both;*/
/*}*/
/*@media only screen and (min-width: 1280px) {*/
/*  .contain {*/
/*    width: 1200px;*/
/*    margin: 0 auto;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 1139px) {*/
/*  .contain .social {*/
/*    width: 1000px;*/
/*    display: block;*/
/*  }*/
/*  .social h1 {*/
/*    margin: 0px;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 950px) {*/
/*  .footer .col {*/
/*    width: 33%;*/
/*  }*/
/*  .footer .col h1 {*/
/*    font-size: 14px;*/
/*  }*/
/*  .footer .col ul li {*/
/*    font-size: 13px;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 500px) {*/
/*    .footer .col {*/
/*      width: 50%;*/
/*    }*/
/*    .footer .col h1 {*/
/*      font-size: 14px;*/
/*    }*/
/*    .footer .col ul li {*/
/*      font-size: 13px;*/
/*    }*/
/*}*/
/*@media only screen and (max-width: 340px) {*/
/*  .footer .col {*/
/*    width: 100%;*/
/*  }*/
/*}*/



/* ===== NAVBAR SECTION ===== */

.navbar-brand {
    color: white;
    padding: 0 15px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 3px;
}

.nav-menu {
    background: transparent;
    padding: 30px;
    position: absolute;
    width: 100%;
    z-index: 9999;
    transition: all .4s;
}

@media (max-width:62em) {
    .nav-menu {
        background: #4f1b1d !important;
    }
}

.nav-item {
    padding: 0 10px;
}

.menu-item {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 11px;
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
}

@media (max-width: 34em) {
    .menu-item {
        padding: 10px 10px 10px;
    }
}

.navbar-toggler {
    cursor: pointer;
    outline: 0;
}

.ham {
    transition: width 0.3s;
    background: #F9F8F7;
}

.line1, .line3  {
    width: 28px;
    height: 2px;
    margin: 6px;
    transition:  0.4s;

}

.change .line1 {
    transform: rotate(-45deg) translate(-3px, 2px);
}

.change .line3 {
    transform: rotate(45deg) translate(-3px, -2px);
}

.menu-item:hover {
    color: #ffd03d !important;
}

.nav-active {
    color: #ffd03d;
}

.dropdown-item {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
}

.costum-navbar {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #4f1b1d !important;
    position: fixed;
}

/* ===== END OF NAVBAR SECTION ===== */


/* ===== HERO SECTION ===== */

/*.hero-section {*/
/*    background: linear-gradient(*/
/*        rgba(0, 0, 0, .7),*/
/*        rgba(0, 0, 0, .6)),*/
/*        url('https://images.pexels.com/photos/240572/pexels-photo-240572.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');*/

/*    height: 100vh;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    background-repeat: no-repeat;*/

/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.text-hero {*/
/*    font-family: 'Josefin Sans', sans-serif;*/
/*    font-size: 70px;*/
/*    font-weight: 700;*/
/*    letter-spacing: 2px;*/
/*}*/

/*.sub-text-hero {*/
/*    font-family: 'Dosis', sans-serif;*/
/*    letter-spacing: 9px;*/
/*    color: orange;*/
/*}*/

.nav-bottom {
    background: transparent !important;
    padding: 30px;
    position: absolute;
    width: 100%;

    bottom: 10px;
}

.item-bottom {
    padding: 0 20px;
    transition: .2s;
    font-size: 20px;
}

.item-bottom:hover {
    background: transparent;
    transform: translateY(-2px);
}

li { list-style: none; }

.container {
	max-width: 100rem;
	margin: 0 auto;
	padding: 0 2rem 2rem;
}
