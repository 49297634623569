.spaceTop {
    margin-top: 20px;
}

.spaceBottom {
    margin-bottom: 5px;
}

.spaceTop200 {
    margin-top: 200px;
}

@media (max-width: 62em) {
    .imageHeight {
        margin-top: calc(35vh - 80px);
    }
}